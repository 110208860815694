import React, { Component } from "react";
import {
  Row,
  Col,
  Table,
  Tag,
  Typography,
  Collapse,
  Result,
  message,
  Radio,
  Transfer,
  Divider,
  Tabs,
  notification,
  Space,
  Select,
  Tooltip,
  Modal,
  Form,
  Input,
  Button,
  DatePicker,
  Breadcrumb,
  Spin,
} from "antd";
import {
  PlayCircleOutlined,
  StopOutlined,
  PauseCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
  Loading3QuartersOutlined,
  PoweroffOutlined,
  ReloadOutlined,
  ScheduleOutlined,
  LoadingOutlined,
  PauseOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  EyeOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import "../../../styles/custom-css/antd-custom.css";
import { SearchBar } from "../../../components";
//import moment from 'moment';
import moment from "moment-timezone";
import {
  getAllJobs,
  scheduleEmail,
  startJob,
  pauseJob,
  resumeJob,
  stopJob,
  deleteJob,
  checkJobName,
} from "../../../service/EmailScheduler/Scheduler";
import {
  getCronExpList,
  getServerList,
  getData,
  getMirthServerDetails,
  getServerByOrgId,
} from "../../../service/mirth/MirthService";
import ChannelTransfer from "../../mirth/EmailScheduler/ChannelTransfer";
import Cron from "react-cron-generator";
import "./cronBuilder.css";
import AuthService from "../../../service/Auth/auth-service";
import { configurationData } from "../../../service/ConfigService/Config";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text, Title } = Typography;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 32, offset: 18 },
    sm: { span: 24, offset: 8 },
  },
};
function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}
function disabledDate(current) {
  return moment().add(-1, "days") >= current;
}
function disabledTime() {
  var today = new Date();
  var hours = today.getHours();
  console.log(hours);
  var minutes = today.getMinutes();
  var seconds = today.getSeconds();
  return {
    disabledHours: () => range(0, 24).splice(0, hours),
    disabledMinutes: () => range(0, minutes),
    //   disabledSeconds: () => [55, 56],
  };
}

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
const reportOptions = [
  { label: "Channel Statistics", value: "channelStatistics" },
  { label: "Error metric", value: "errorMetric" },
  { label: "Usage", value: "usage" },
];

const alertOptions = [
  { label: "Error metric", value: "errorMetric" },
  { label: "Server Down", value: "serverDown" },
];
const cronExpressionList = [
  {
    expression: "0 15 10 * * ? *",
    key: "Fire at 10:15am every day",
  },
  {
    expression: "0 30 10-13 ? * WED,FRI",
    key: "Fires at 10:30, 11:30, 12:30, and 13:30, on every Wednesday and Friday",
  },
];
const contextList = [
  {
    key: "Poller Data Delete Job",
    val: "pollerDataDeleteJob",
  },
  {
    key: "Channel Statistics Delete Job",
    val: "channelStatisticsDeleteJob",
  },
  {
    key: "Pg Toast Delete Job",
    val: "pgToastDeleteJob",
  },
  {
    key: "User Audit Report",
    val: "userAuditReport",
  },
  {
    key: "Session Monitoring Report",
    val: "sessionMonitoring",
  },
];
const openNotification = (message) => {
  notification.success({
    message: message,
  });
};
export default class AdminEmailScheduler extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      spin: true,
      scheduledJobs: [],
      cloneScheduledJobs: [],
      serverOptions: [],
      timeZones: ["Asia/Kolkata", "America/New_York"],
      jobTypeValue: 1,
      jobType: "oneTimeJob",
      isOneTimeJob: true,
      isPollerDataDeleteJob: false,
      isChannelStatisticsDeleteJob: false,
      isPgToastDeleteJob: false,
      isUserAuditReport: false,
      isSessionMonitoring: false,
      isServerChanged: Math.random(),
      channelStatisticsData: [],
      channelListData: [],
      channelThresholdList: [],
      targetKeys: [],
      cronValue: "",
      host: null,
      endPoint: null,
      userName: null,
      password: null,
      access: "",
      accessToken: null,
      modalTitle: "",
      isModalVisible: false,
      modalMessage: "",
      currentServerName: "",
      serverName: [],
      alertType: "",
      currentUser: {},
      activeKey: "2",
      isRefreshData: false,
      refreshValue: "",
      resultStatus: '',
      resultTitle: '',
      isError: false,
      ischannelTrends: false,
      emailId: [],
      page:'adminEmailScheduler',
      contextList:[],
      interval:[],
      validateMessages:[]
    };

    this.handleActionClick = this.handleActionClick.bind(this);
    this.handleJobTypeChange = this.handleJobTypeChange.bind(this);
    this.handleContextChange = this.handleContextChange.bind(this);
    this.handleCronChange = this.handleCronChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChannelTransfer = this.handleChannelTransfer.bind(this);
    this.handleChannelListChange = this.handleChannelListChange.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleModalView = this.handleModalView.bind(this);
    this.onKeyChange = this.onKeyChange.bind(this);
    // this.handleReset=this.handleReset.bind(this);
    // this.handleRfreshData=this.handleRfreshData.bind(this);
    this.readOnly = true;
    this.myRef = React.createRef();
    
  }

  componentDidMount() {
    let user = AuthService.getCurrentUser();
    configurationData(this.state.page).then((res) =>{
      if(res.code==200){
        let jsonData=JSON.parse(res.result[0].config);
        console.log(jsonData)
      this.setState({contextList:jsonData.contextList,interval:jsonData.interval,resultStatus:jsonData.status.resultStatus,
        resultTitle:jsonData.status.resultTitle,validateMessages:jsonData.validateMessages}
        )
      
    
      
  

    getAllJobs().then((res) => {
      if (res.statusCode == 200) {
        let jobs = res.data;
        let orgJobs = [];
        for (let i = 0; i < jobs.length; i++) {
          if (jobs[i].jobDetails.servers === null) {
            orgJobs.push(jobs[i]);
          }
        }
        
        this.setState({
          currentUser: user,
          spin: false,
          scheduledJobs: orgJobs,
          cloneScheduledJobs: orgJobs,
          access: AuthService.getCurrentUserAccess(),
        });
      }
    });
    
  }
})
  }
  onReset = () => {
    this.formRef.current.resetFields();

    this.setState({
      channelListData: [],
      accessToken: null,
      isPollerDataDeleteJob: false,
      isChannelStatisticsDeleteJob: false,
      isPgToastDeleteJob: false,
      isUserAuditReport: false,
      isSessionMonitoring: false,
      isOneTimeJob: true,
    });
  };
  onCancel = () => {
    this.formRef.current.resetFields();

    this.setState({
      activeKey: "2",
      channelListData: [],
      accessToken: null,
      isPollerDataDeleteJob: false,
      isChannelStatisticsDeleteJob: false,
      isPgToastDeleteJob: false,
      isUserAuditReport: false,
      isSessionMonitoring: false,
      isOneTimeJob: true,
    });
  };
  handleSearch(data) {
    this.setState({ scheduledJobs: data });
  }

  // handleServerChange(val) {

  //             if (this.state.isZeroRecievedAlert || this.state.isStdDevAlert || this.state.isStdDevObservation || this.state.isErrorReport) {
  //                 this.setState({ spin: true,accessToken:val });
  //                 getMirthServerDetails(val).then(serverRes=>{
  //                     let channelArr = [];
  //                     if (serverRes.code == 200 && serverRes.result.length !=0) {
  //                         let details = JSON.parse(serverRes.result[0].details);
  //                         let res =JSON.parse( details.channelStatus);
  //                         let values= res.list.dashboardStatus;
  //                         let finalArray =[];
  //                         if (values !== null && !Array.isArray(values)) {
  //                             finalArray.push(values);
  //                             this.setState({ spin: false,channelData:finalArray ,cloneChannelData:finalArray})
  //                           }
  //                         else
  //                         {
  //                             finalArray =values;
  //                             this.setState({ spin: false,channelData:finalArray ,cloneChannelData:finalArray })
  //                         }
  //                         channelArr = finalArray;
  //                     }
  //                     let finalArr = [];
  //                     for (let l = 0; l < channelArr.length; l++) {
  //                         let channeljson = { key: channelArr[l].channelId, name: channelArr[l].name, threshold: 50 };
  //                         finalArr.push(channeljson);
  //                     }
  //                     console.log(finalArr);
  //                     this.setState({ channelListData: finalArr, targetKeys: [], spin: false })
  //                 })

  //             }
  //             else if (this.state.isErrorPercentAlert || this.state.isRawDeltaAlert) {
  //                 this.setState({ isServerChanged: Math.random() ,accessToken:val });
  //             }

  // }

  handleActionClick(record, operation) {
    console.log(operation);

    if (operation == "start") {
      startJob(record.jobName).then((response) => {
        if (response.statusCode == 200) {
          getAllJobs().then((res) => {
            if (res.statusCode == 200) {
              let jobs = res.data;
              let orgJobs = [];
              for (let i = 0; i < jobs.length; i++) {
                if (jobs[i].jobDetails.servers === null) {
                  orgJobs.push(jobs[i]);
                }
              }
              this.setState({
                scheduledJobs: orgJobs,
                cloneScheduledJobs: orgJobs,
              });
              openNotification("Job Started successfully.");
            }
          });
        }
      });
    } else if (operation == "pause") {
      pauseJob(record.jobName).then((response) => {
        if (response.statusCode == 200) {
          getAllJobs().then((res) => {
            if (res.statusCode == 200) {
              let jobs = res.data;
              let orgJobs = [];
              for (let i = 0; i < jobs.length; i++) {
                if (jobs[i].jobDetails.servers === null) {
                  orgJobs.push(jobs[i]);
                }
              }
              this.setState({
                scheduledJobs: orgJobs,
                cloneScheduledJobs: orgJobs,
              });
              openNotification("Job Paused successfully.");
            }
          });
        }
      });
    } else if (operation == "resume") {
      resumeJob(record.jobName).then((response) => {
        if (response.statusCode == 200) {
          getAllJobs().then((res) => {
            if (res.statusCode == 200) {
              let jobs = res.data;
              let orgJobs = [];
              for (let i = 0; i < jobs.length; i++) {
                if (jobs[i].jobDetails.servers === null) {
                  orgJobs.push(jobs[i]);
                }
              }
              this.setState({
                scheduledJobs: orgJobs,
                cloneScheduledJobs: orgJobs,
              });
              openNotification("Job Resumed successfully.");
            }
          });
        }
      });
    } else if (operation == "delete") {
      let request = { jobName: record.jobName };
      console.log(request);
      deleteJob(record.jobName,0).then((response) => {
        if (response.statusCode == 200) {
          getAllJobs().then((res) => {
            if (res.statusCode == 200) {
              let jobs = res.data;
              let orgJobs = [];
              for (let i = 0; i < jobs.length; i++) {
                if (jobs[i].jobDetails.servers === null) {
                  orgJobs.push(jobs[i]);
                }
              }
              this.setState({
                scheduledJobs: orgJobs,
                cloneScheduledJobs: orgJobs,
              });
              openNotification("Job Deleted successfully.");
            }
          });
        }
      });
    } else if (operation == "stop") {
      stopJob(record.jobName).then((response) => {
        if (response.statusCode == 200) {
          getAllJobs().then((res) => {
            if (res.statusCode == 200) {
              let jobs = res.data;
              let orgJobs = [];
              for (let i = 0; i < jobs.length; i++) {
                if (jobs[i].jobDetails.servers === null) {
                  orgJobs.push(jobs[i]);
                }
              }
              this.setState({
                scheduledJobs: orgJobs,
                cloneScheduledJobs: orgJobs,
              });
              openNotification("Job Stopped successfully.");
            }
          });
        }
      });
    }
  }
  handleChannelListChange = (items) => {
    this.setState({ targetKeys: items });
  };
  handleJobTypeChange(e) {
    let val = e.target.value;
    let type = "oneTimeJob";
    let booleanJob = this.state.isOneTimeJob;
    if (val == 1) {
      booleanJob = true;
      type = "oneTimeJob";
    } else {
      booleanJob = false;
      type = "cronJob";
    }
    this.setState({
      jobTypeValue: e.target.value,
      jobType: type,
      isOneTimeJob: booleanJob,
    });
  }
  handleContextChange(val) {
    this.formRef.current.resetFields(["server", "servers"]);
    this.setState({
      accessToken: null,
      isServerChanged: Math.random(),
      channelListData: [],
      targetKeys: [],
    });
    if (val == "pollerDataDeleteJob") {
      this.setState({
        isPollerDataDeleteJob: true,
        isChannelStatisticsDeleteJob: false,
        isPgToastDeleteJob: false,
        isUserAuditReport: false,
        isSessionMonitoring: false,
      });
    } 
    else if (val == "channelStatisticsDeleteJob") {
      this.setState({
        isPollerDataDeleteJob: false,
        isChannelStatisticsDeleteJob: true,
        isPgToastDeleteJob: false,
        isUserAuditReport: false,
        isSessionMonitoring: false,
      });
    }
    else if (val == "pgToastDeleteJob") {
      this.setState({
        isPollerDataDeleteJob: false,
        isChannelStatisticsDeleteJob: false,
        isPgToastDeleteJob: true,
        isUserAuditReport: false,
        isSessionMonitoring: false,
      });
    }else if (val == "userAuditReport") {
      this.setState({
        isPollerDataDeleteJob: false,
        isChannelStatisticsDeleteJob: false,
        isPgToastDeleteJob: false,
        isUserAuditReport: true,
        isSessionMonitoring: false,
      });
    } else if (val == "sessionMonitoring") {
      this.setState({
        isPollerDataDeleteJob: false,
        isChannelStatisticsDeleteJob: false,
        isPgToastDeleteJob: false,
        isUserAuditReport: false,
        isSessionMonitoring: true,
      });
    }
  }

  handleChannelTransfer(val) {
    this.setState({ channelThresholdList: val });
  }
  error = () => {
    message.error("Please select the channels");
  };
  handleSubmit(val) {
    this.setState({ spin: true });
    let request = {};
    request.email = val.email;
    request.jobName = val.jobName;
    request.timeZone = "America/New_York";
    request.context = val.context;
    if (this.state.jobTypeValue == 1) {
      console.log(
        moment(val.dateTime)
          .tz("America/New_York")
          .format("YYYY-MM-DDTHH:mm:ss")
      );
      //   request.dateTime = moment(val.dateTime).format("YYYY-MM-DDTHH:mm:ss");
      request.dateTime = moment(val.dateTime)
        .tz("America/New_York")
        .format("YYYY-MM-DDTHH:mm:ss");
      request.cronExpression = null;
      request.interval = null;
    } else {
      request.dateTime = null;
      request.cronExpression = val.cronExpression;
      request.interval = val.cronExpression.substring(4, 6).trim();
    }

    if (val.context == "pollerDataDeleteJob" || val.context == "channelStatisticsDeleteJob" || val.context == "pgToastDeleteJob") {
      request.servers = null;
      request.channels = null;
      request.timeRange = null;
      request.email = [];
    } else if (
      val.context == "userAuditReport" ||
      val.context == "sessionMonitoring"
    ) {
      request.channels = null;
      request.timeRange = val.interval;
      request.servers = null;
    }

    //request.body = val.subject;
    console.log(request);

    scheduleEmail(request).then(response => {
        if (response.statusCode == 200) {
            let jobs = response.data;
            let orgJobs=[];
            for(let i=0;i<jobs.length;i++)
            {
                if(jobs[i].jobDetails.servers === null)
                {
                    orgJobs.push(jobs[i])
                }

            }
            this.setState({ spin: false, scheduledJobs: orgJobs, isOneTimeJob: true, isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isPollerDataDeleteJob:false,isChannelStatisticsDeleteJob: false,isPgToastDeleteJob: false,isChannelStats:false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isErrorReport:false, cloneScheduledJobs: orgJobs ,activeKey: '2'})
            this.formRef.current.resetFields();
            openNotification('Job scheduled successfully!');
            // getAllJobs().then(res => {
            //     if (res.statusCode == 200) {
            //         // this.setState({ spin: false, scheduledJobs: res.data })
            //         let jobs = res.data;
            //         let orgJobs=[];
            //         for(let i=0;i<jobs.length;i++)
            //         {
            //             if(jobs[i].jobDetails.servers === null)
            //             {
            //                 orgJobs.push(jobs[i])
            //             }

            //         }
            //         this.setState({ spin: false, scheduledJobs: orgJobs, isOneTimeJob: true, isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isPollerDataDeleteJob:false,isChannelStats:false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isErrorReport:false, cloneScheduledJobs: orgJobs ,activeKey: '2'})
            //         this.formRef.current.resetFields();
            //         openNotification('Job scheduled successfully!');
            //     }
            // });
        }
    })
  }
  refreshData() {
    this.setState({ spin: true, isRefreshData: true });
    getAllJobs().then((res) => {
      if (res.statusCode == 200) {
        let jobs = res.data;
        let orgJobs = [];
        for (let i = 0; i < jobs.length; i++) {
          if (jobs[i].jobDetails.servers === null) {
            orgJobs.push(jobs[i]);
          }
        }

        this.setState({
          spin: false,
          scheduledJobs: orgJobs,
          cloneScheduledJobs: orgJobs,
          isRefreshData: true,
        });
      }
      //this.myRef.current.reset();
    });
  }

  handleCancel() {
    this.setState({ isModalVisible: false });
  }
  handleModalView(data) {
    console.log(data);
    if (
      data.context == "userAuditReport" ||
      data.context == "sessionMonitoring"
    ) {
      this.setState({ isModalVisible: true, emailId: data.jobDetails.email });
    } else {
      this.setState({
        alertType: data.jobDetails.context,
        modalMessage: data.jobDetails,
        serverName: data.jobDetails.servers,
        isModalVisible: true,
        emailId: data.jobDetails.email,
      });
    }
    console.log(data.jobDetails.servers);
  }
  handleCronChange(val) {
    console.log(val);
    this.setState({ cronValue: val });
  }
  onKeyChange(val) {
    this.setState({ activeKey: val });
  }
  render() {
    const {
      ischannelTrends,
      isUserAuditReport,
      isSessionMonitoring,
      emailId,
      serverName,
      spin,
      access,
      serverOptions,
      isError,
      accessToken,
      resultStatus,
      resultTitle,
      alertType,
      activeKey,
      modalMessage,
      scheduledJobs,
      cloneScheduledJobs,
      isOneTimeJob,
      isErrorPercentAlert,
      isRawDeltaAlert,
      isServerDownAlert,
      isZeroRecievedAlert,
      isErrorReport,
      isStdDevAlert,
      isStdDevObservation,
      isServerChanged,
      isModalVisible,
      contextList,
      interval,
      validateMessages
    } = this.state;

    const getFullDate = (date) => {
      if (date !== null) {
        const value = moment(date)
          .tz("America/New_York")
          .format("YYYY-MM-DD HH:mm:ss");
        // const value = new Date(date).toLocaleString('en-US', { hour12: true });
        return value;
      }
    };

    const renderTag = (tag) => {
      if (tag == "SCHEDULED")
        return (
          <Tag icon={<ScheduleOutlined />} color="orange">
            {tag}
          </Tag>
        );
      else if (tag == "RUNNING")
        return (
          <Tag color="warning" icon={<LoadingOutlined />}>
            {tag}
          </Tag>
        );
      else if (tag == "PAUSED")
        return (
          <Tag color="processing" icon={<PauseOutlined />}>
            {tag}
          </Tag>
        );
      else if (tag == "COMPLETE")
        return (
          <Tag color="success" icon={<CheckOutlined />}>
            {tag}
          </Tag>
        );
      else
        return (
          <Tag color="error" icon={<CloseCircleOutlined />}>
            {tag}
          </Tag>
        );
    };
    const actionStatus = (status, action) => {
      if (access == "read&write") {
        if (action == "start") {
          if (status == "RUNNING") {
            return true;
          } else {
            return false;
          }
        } else if (action == "pause") {
          if (status != "SCHEDULED") {
            return true;
          } else {
            return false;
          }
        } else if (action == "resume") {
          if (status != "PAUSED") {
            return true;
          } else {
            return false;
          }
        } else if (action == "delete") {
          if (status == "RUNNING") {
            return true;
          } else {
            return false;
          }
        } else if (action == "stop") {
          if (status != "RUNNING") {
            return true;
          } else {
            return false;
          }
        } else if (action == "restore") {
          return false;
        }
      } else {
        return true;
      }
    };
    const accessStatus = () => {
      if (access == "read&write") {
        return false;
      } else {
        return true;
      }
    };
    const scheduledJobsColumns = [
      {
        title: "Error Type",
        dataIndex: "",
        key: "",
        render: (text, record) => (
          <>
            {record.jobDetails.context
              .replace(/([A-Z])/g, " $1")
              .replace(/^./, (str) => str.toUpperCase())}
          </>
        ),
      },
      { title: "Job Name", dataIndex: "jobName", key: "jobName" },
      {
        title: "Scheduled Time",
        dataIndex: "scheduleTime",
        key: "scheduleTime",
        render: (text, record) => <>{getFullDate(text)}</>,
      },
      {
        title: "Last Fired Time",
        dataIndex: "lastFiredTime",
        key: "lastFiredTime",
        render: (text, record) => <>{getFullDate(text)}</>,
      },
      {
        title: "Next Fire Time",
        dataIndex: "nextFireTime",
        key: "nextFireTime",
        render: (text, record) => <>{getFullDate(text)}</>,
      },
      {
        title: "Job Status",
        dataIndex: "jobStatus",
        key: "jobStatus",
        render: (text) => renderTag(text),
      },
      {
        title: "More Info",
        dataIndex: "",
        key: "x",

        render: (text, record) => (
          <>
          {record.jobDetails.context !== "pollerDataDeleteJob" &&
           record.jobDetails.context !== "channelStatisticsDeleteJob" &&
           record.jobDetails.context !== "pgToastDeleteJob" ? (
            <Tooltip title="More Info">
              <a
                onClick={() => {
                  this.handleModalView(record);
                }}
              >
                <EyeOutlined />
              </a>
            </Tooltip>
          ) : (
            <></>
          )}
        </>
        
        ),
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "",
        render: (text, record) => (
          <>
            <Space>
              <Tooltip title="Start">
                <Button
                  type="text"
                  disabled={actionStatus(record.jobStatus, "start")}
                  onClick={() => {
                    this.handleActionClick(record, "start");
                  }}
                  icon={<PlayCircleOutlined />}
                />
              </Tooltip>
              <Tooltip title="Pause">
                <Button
                  type="text"
                  disabled={actionStatus(record.jobStatus, "pause")}
                  onClick={() => {
                    this.handleActionClick(record, "pause");
                  }}
                  icon={<PauseCircleOutlined />}
                />
              </Tooltip>
              <Tooltip title="Resume">
                <Button
                  type="text"
                  disabled={actionStatus(record.jobStatus, "resume")}
                  onClick={() => {
                    this.handleActionClick(record, "resume");
                  }}
                  icon={<Loading3QuartersOutlined />}
                />
              </Tooltip>

              {/* <Tooltip title="Stop">
                                <Button type="text" disabled={actionStatus(record.jobStatus, 'stop')} onClick={() => { this.handleActionClick(record, 'stop') }} icon={<StopOutlined />} />

                            </Tooltip> */}
              <Tooltip title="Delete">
                <Button
                  type="text"
                  disabled={actionStatus(record.jobStatus, "delete")}
                  onClick={() => {
                    this.handleActionClick(record, "delete");
                  }}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
              
            </Space>
          </>
        ),
      },
    ];

    const channelColumns = [
      { title: "Id", dataIndex: "id", key: "id" },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
    ];
    // const contentBodyOptions = [
    //     { label: 'Channel Statistics', value: 'channelStatistics' },
    //     { label: 'Error metric', value: 'errorMetric' },
    //     { label: 'Usage', value: 'usage' },
    //   ];
    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Admin</Breadcrumb.Item>
          <Breadcrumb.Item>Email Scheduler</Breadcrumb.Item>
        </Breadcrumb>

        <div className="content_panel">
          <Spin size="large" spinning={spin}>
            <Modal
              title="Job Details"
              visible={isModalVisible}
              footer={null}
              onCancel={this.handleCancel}
              width={900}
              bodyStyle={{ overflowY: "scroll", height: 380 }}
            >
            <Title level={4}>
                Report Type: 
                {alertType
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, (str) => str.toUpperCase())}
              </Title>

              <Collapse
                defaultActiveKey={["1"]}
                style={{ marginTop: "10px" }}
                accordion
              >
               
                <Panel header="To Address" key="2">
                  <p>
                    <ul>
                      {emailId.map((i) => (
                        <li>{i}</li>
                      ))}
                    </ul>
                  </p>
                </Panel>
              </Collapse>

            </Modal>
            <Row>
              <Col span={12}>
                <div class="content_panel_heading">Email Scheduler</div>
              </Col>
              {/* <Col span={12}>
                {isError ? (
                  <></>
                ) : (
                  <Link to="/mirth/deleted-jobs">
                    <Button
                      style={{
                        float: "right",
                        borderRadius: "7px",
                        backgroundColor: "#efefef",
                        fontFamily: "AktivGroteskMedium",
                        border: "none",
                      }}
                      disabled={actionStatus("null", "restore")}
                    >
                      <HistoryOutlined
                        style={{ color: "#fc9558", fontSize: "16px" }}
                      />{" "}
                      Restore Jobs
                    </Button>
                  </Link>
                )}
              </Col> */}
            </Row>
            {isError ? (
              <>
                <div class="divider-1">
                  {" "}
                  <span></span>
                </div>
                <Result
                  status={resultStatus}
                  title={resultTitle}
                  subTitle={""}
                  extra={
                    resultStatus == "500" ? (
                      <></>
                    ) : (
                      <Link to="/settings/server">
                        <Button type="primary" style={{ borderRadius: "7px" }}>
                          Add Server
                        </Button>
                      </Link>
                    )
                  }
                />
              </>
            ) : (
              <>
                <Tabs
                  style={{ marginTop: "10px" }}
                  activeKey={activeKey}
                  onChange={this.onKeyChange}
                  tabPosition="top"
                >
                  <TabPane tab=" Jobs" key="2">
                    <Tooltip title="Refresh">
                      <Button
                        type="primary"
                        shape="circle"
                        icon={<ReloadOutlined />}
                        onClick={this.refreshData}
                      />
                    </Tooltip>
                    {/* <Button style={{ color: '#008fcd', float: 'right', marginRight: 10 }} onClick={this.refreshData} >
                                            <ReloadOutlined /> Refresh
                                </Button> */}
                    <Divider type="vertical" />

                    <SearchBar
                      data={cloneScheduledJobs}
                      handler={this.handleSearch}
                      ref={this.myRef}
                    ></SearchBar>

                    <Table
                      style={{ marginTop: "10px" }}
                      className="email_scheduler"
                      columns={scheduledJobsColumns}
                      dataSource={scheduledJobs}
                    />
                  </TabPane>
                  <TabPane disabled={actionStatus()} tab="Create Job" key="1">
                    <div className="email_scheduler_form_panel">
                      <Form
                        name="scheduler_form"
                        ref={this.formRef}
                        hideRequiredMark
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        onFinish={this.handleSubmit}
                        validateMessages={validateMessages}
                      >
                        <Form.Item
                          name="jobName"
                          label="Job Name"
                          rules={[
                            {
                              required: true,
                              //   message: 'Please input Job name!',
                            },
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                return new Promise((resolve, reject) => {
                                  checkJobName(value).then((res) => {
                                    console.log(res);
                                    if (res.data == true) {
                                      reject("Job Name already exists!");
                                    } else {
                                      resolve();
                                    }
                                  });
                                });
                              },
                            }),
                          ]}
                        >
                          <Input placeholder="Enter Job name" />
                        </Form.Item>

                        <Form.Item name="jobType" label="Job Type">
                          <Radio.Group
                            onChange={this.handleJobTypeChange}
                            defaultValue={1}
                          >
                            <Radio value={1}>One time job</Radio>
                            <Radio value={2}>Cron job</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          name="dateTime"
                          label="Date Time"
                          style={{ display: isOneTimeJob ? "" : "none" }}
                          rules={[
                            {
                              required: isOneTimeJob,
                              //  message: 'Please input date time!',
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                var today = new Date();
                                if (getFieldValue("dateTime") < today) {
                                  return Promise.reject(
                                    new Error("The seleceted date is passed")
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),

                          ]}
                        >
                          <DatePicker
                            readOnly={this.readOnly}
                            style={{
                              width: "300px",
                              height: "35px",
                              borderRadius: "7px",
                            }}
                            showTime
                            disabledDate={disabledDate}
                            disabledTime={disabledTime}
                          />
                        </Form.Item>

                        <Form.Item
                          name="cronExpression"
                          label="Cron Expression"
                          style={{ display: isOneTimeJob ? "none" : "" }}
                        >
                          <Cron
                            onChange={(e) => {
                              this.handleCronChange(e);
                            }}
                            showResultText={true}
                            showResultCron={true}
                          />
                        </Form.Item>


                        <Form.Item
                          name="context"
                          label="Report Type"
                          rules={[
                            {
                              required: true,
                              // message: 'Please input context!',
                            },
                          ]}
                        >
                          {contextList.length !== 0 && (
                            <Select
                              placeholder="Select type"
                              style={{ width: "300px" }}
                              onChange={this.handleContextChange}
                            >
                              {contextList.map((context) => (
                                <Option key={context.val} value={context.val}>
                                  {context.key}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>

                        
                        <Form.List name="email" initialValue={[""]} >
                          {(fields, { add, remove }, { errors }) => (
                            <>
                              {fields.map((field, index) => (
                                <Form.Item
                                  {...(index === 0
                                    ? ""
                                    : formItemLayoutWithOutLabel)}
                                  label={index === 0 ? "To Email" : ""}
                                  required={false}
                                  style={{
                                    display:
                                      isSessionMonitoring ||
                                      isUserAuditReport 
                                        ? ""
                                        : "none", marginBottom: 8
                                  }}
                                 // style={{ display: "flex", marginBottom: 8 }}
                                  key={field.key}
                                >
                                  <Form.Item
                                    {...field}
                                    validateTrigger={["onChange", "onBlur"]}
                                    rules={[
                                      {
                                        required: (isUserAuditReport || isSessionMonitoring),
                                        whitespace: true,
                                        message:
                                          "Please input email or delete this field.",
                                        //type: 'email'
                                      },
                                      {
                                        message: "Email is not valid",
                                        type: "email",
                                      },
                                    ]}
                                    noStyle
                                  >
                                    <Input placeholder="Enter email" />
                                  </Form.Item>
                                  {fields.length > 1 ? (
                                    <MinusCircleOutlined
                                      className="dynamic-delete-button"
                                      onClick={() => remove(field.name)}
                                    />
                                  ) : null}
                                </Form.Item>
                              ))}
                              <Form.Item {...tailFormItemLayout}
                               style={{
                                display:
                                  isSessionMonitoring ||
                                  isUserAuditReport 
                                    ? ""
                                    : "none"
                              }}>
                                <Button
                                  type="dashed"
                                  onClick={() => add()}
                                  icon={<PlusOutlined />}
                                >
                                  Add Email
                                </Button>

                                <Form.ErrorList errors={errors} />
                              </Form.Item>
                            </>
                          )}
                        </Form.List>

                        <Form.Item
                          name="interval"
                          label="Interval"
                          style={{
                            display:
                              isSessionMonitoring ||
                              isUserAuditReport ||
                              ischannelTrends
                                ? ""
                                : "none",
                          }}
                        >
                          <Select
                            style={{ width: "300px" }}
                            placeholder="Select the interval">
                            {interval.map((int) => (
                                                        <Option key={int.key} value={int.key}>{int.key}</Option>
                                                    ))

                                                    }
                                                </Select>
                        </Form.Item>


                        <Form.Item {...tailFormItemLayout}>
                          <Button
                            htmlType="submit"
                            type="primary"
                            style={{ borderRadius: "7px" }}
                          >
                            SUBMIT{" "}
                          </Button>
                          <Button
                            htmlType="button"
                            style={{
                              borderRadius: "7px",
                              backgroundColor: "#efefef",
                              marginLeft: "10px",
                            }}
                            onClick={this.onCancel}
                          >
                            Cancel
                          </Button>
                          <Button
                            htmlType="button"
                            style={{
                              backgroundColor: "#efefef",
                              margin: "0 8px",
                            }}
                            onClick={this.onReset}
                          >
                            {" "}
                            Reset
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </TabPane>
                </Tabs>
              </>
            )}
          </Spin>
        </div>
      </>
    );
  }
}
